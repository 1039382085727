import React, { useEffect, useState } from "react";
import "./App.css";
import Sidebar from "./Components/Sidebar";
import Chat from "./Components/Chat";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Login from "./Components/Login";
import { useStateValue } from "./StateProvider";
import { auth } from "./firebase";
import { actionTypes } from "./reducer";
import Loading from "./Components/Loading";
import socketIOClient from "socket.io-client";
import useSound from 'use-sound';
import soundfile from './notif.mp3'
import _ from 'lodash';


const ENDPOINT = "https://chatio.ditukar.in"
let swReg = null;

function App() {

  const [{ user }, dispatch] = useStateValue();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [phone, setPhone] = useState([]);
  const [soc, setSoc] = useState([]);
  const [akun, setAkun] = useState([]);



  if ('serviceWorker' in navigator && 'PushManager' in window) {
    console.log('Service Worker and Push is supported');

    if (navigator.serviceWorker.active) {
      console.log('Service worker already active');
      //Register the service worker
    }
    else {
      navigator.serviceWorker
        .register('/sw.js')
        .then(r => {
          console.log('Service Worker is registered', r);
          // We are storing the service worker, globally
          swReg = r
        })
        .catch(error => {
          console.error('Service Worker Error', error);
        });
    }
  } else {
    console.warn('Push messaging is not supported');
  }

  useEffect(() => {
    fetch("https://mongo.instaforex.io/getchat")
      .then(response => response.json())
      .then(data => {
        console.log(data)
        const notd = _.filter(data, o => { return (o.from !== '447480820464@c.us' && o.from !== 'status@broadcast') });
        const cfromd = _.filter(data, o => { return (o.from === '447480820464@c.us') });
        const uniqfrom = _.uniqBy(notd, o => o.from);
        const uniqto = _.uniqBy(cfromd, o => o.to)
        for (let i = 0; i < uniqfrom.length; i++) {
          const dreply = _.find(uniqto, o => o.to === uniqfrom[i].from)
          uniqfrom[i].tr = dreply?.t;
          uniqfrom[i].bodyr = dreply?.body;
        }
        //console.log(uniqfrom)
        setPhone(uniqfrom);
        setData(data);
      })
  }, [soc])

  const pushNotif = (v) => {

    if (Notification.permission === "granted") {
      // If it's okay let's create a notification
      const options = {
        body: v.body,
        icon: v.sender.profilePicThumbObj?.imgFull,
      };
      swReg.showNotification(v.from + ' ' + v.notifyName, options);
      console.log('notif sent')
    }
    // Otherwise, we need to ask the user for permission
    else if (Notification.permission !== "denied") {
      Notification.requestPermission().then(function (permission) {
        // If the user accepts, let's create a notification
        if (permission === "granted") {
          const options = {
            body: v.body,
            icon: v.sender.profilePicThumbObj?.imgFull,
          };
          swReg.showNotification(v.from + ' ' + v.notifyName, options);
        }
      });
    }
  }



  useEffect(() => {
    const socket = socketIOClient(ENDPOINT);
    socket.on("wa", data => {
      setTimeout(pushNotif(data), 2000)
      setTimeout(setSoc(data), 2000)
    });
  }, []);

  const fsetakun = (x) => {
    setAkun(x)
  }

  const [play, { stop }] = useSound(
    soundfile,
    { volume: 1 }
  );

  useEffect(() => {
    soc.from !== '447480820464@c.us' ? play() : console.log('fromd')
  }, [soc]);


  useEffect(() => {
    setLoading(true);
    const listener = auth.onAuthStateChanged((authUser) => {
      setLoading(false);
      if (authUser) {
        if (authUser.email === 'hafid@instaforex.co.id' || authUser.email === 'hafidzaini@gmail.com') {
          dispatch({
            type: actionTypes.SET_USER,
            user: authUser,
          });
        } else {
          auth.signOut()
        }
      } else {
        dispatch({
          type: actionTypes.SET_USER,
          user: null,
        });
      }
    });
    return () => listener();
  }, [dispatch]);



  if (loading) {
    return <Loading />;
  }
  return (
    <div className="app">
      {!user ? (
        <Login />
      ) : (
        <div className="app__body">
          <Router basename={process.env.PUBLIC_URL}>
            <Switch>
              <Route path="/" exact>
                <Sidebar hide={false} phone={phone} akun={akun} fsetakun={fsetakun} />
                <Chat hide={true} data={data} />
                <div className="project__info">
                  <table>
                    <tr>
                      <th>
                        n
                      </th>
                      <th>
                        tgl
                      </th>
                      <th>
                        akun
                      </th>
                      <th>
                        nama
                      </th>
                      <th>
                        alamat
                      </th>
                      <th>
                        kota
                      </th>
                    </tr>
                    {akun?.map((val, i) => (
                      <tr>
                        <th>
                          {i}
                        </th>
                        <th>
                          {val.date.substring(0, 10)}
                        </th>
                        <th>
                          {val.account}
                        </th>
                        <th>
                          {val.name}
                        </th>
                        <th>
                          {val.adr}
                        </th>
                        <th>
                          {val.city}
                        </th>
                      </tr>
                    ))}
                  </table>
                </div>
              </Route>
              <Route path="/c/:roomId">
                <Sidebar hide={true} phone={phone} />
                <Chat hide={false} data={data} />
              </Route>
            </Switch>
          </Router>
        </div>
      )}
    </div>
  );
}

export default App;
