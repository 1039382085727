import React from "react";
import { Avatar } from "@material-ui/core";
import "./SidebarChat.css";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { formatDistance } from 'date-fns';
import { id } from "date-fns/locale";


function SidebarChat({ phone }) {

  return (
    < div>
      {phone.map((value) => {
        return <Link to={`/c/${value.from}`}><div className="sidebarChat" key={value.sender}>

          <Avatar src={value.sender.profilePicThumbObj?.imgFull ?? "https://pipsologi.instaforexindonesiafix.com/content/images/2022/04/instaforex-red-2.png"} />

          <div className="sidebarChat__info " key={value.sender}>
            <h5>{value.sender.pushname} <span style={{ "color": "grey" }}>{value.from.split('@')[0]}</span></h5>
            {value.t <= value.tr ?
              <div>
                <div>
                  <p className="c">
                    {value.body?.substring(0, 20)}
                    {value.body?.length > 20 && "..."} <span className="tstamp">{formatDistance(new Date(value.t * 1000 || new Date()), new Date(), { addSuffix: true, locale: id })}</span>
                  </p>
                </div>
                <div>

                </div>
                <h6 className="cr">
                  {value.bodyr?.substring(0, 20)}
                  {value.bodyr?.length > 30 && "..."} <span className="tstamp">{formatDistance(new Date(value.tr * 1000 || new Date()), new Date(), { addSuffix: true, locale: id })}</span>
                </h6>
              </div>
              :
              <div>
                <h6 className="cr">
                  {value.bodyr?.substring(0, 20)}
                  {value.bodyr?.length > 20 && "..."} <span className="tstamp">{formatDistance(new Date(value.tr * 1000 || new Date()), new Date(), { addSuffix: true, locale: id })}</span>
                </h6>
                <h6 className="c">
                  {value.body?.substring(0, 20)}
                  {value.body?.length > 20 && "..."} <span className="tstamp">{formatDistance(new Date(value.t * 1000 || new Date()), new Date(), { addSuffix: true, locale: id })}</span>
                </h6>
              </div>
            }
          </div>
        </div>
        </Link>
      })}
    </div >
  );
}

export default SidebarChat;
