import React, { useState, useEffect } from "react";
import { Avatar, ClickAwayListener, IconButton } from "@material-ui/core";
import DonutLargeIcon from "@material-ui/icons/DonutLarge";
import ChatIcon from "@material-ui/icons/Chat";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import "./Sidebar.css";
import SidebarChat from "./SidebarChat";
import { SearchOutlined } from "@material-ui/icons";
import { auth } from "../firebase";
import { useStateValue } from "../StateProvider";
import { actionTypes } from "../reducer";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

function Sidebar({ hide, phone, fsetakun }) {
  const [rooms, setRooms] = useState([]);
  const [{ user }, dispatch] = useStateValue();
  const [search, setSearch] = useState("");
  const [s, setS] = useState("");

  const [showdropdown, setDropdown] = useState(false);
  const history = useHistory();
  const mainPage = () => {
    history.push("/")
  }

  const handleChange = (event) => {
    setS(event.target.value);
  };

  const signOut = () => {
    auth
      .signOut()
      .then(() => {
        dispatch({
          type: actionTypes.SET_USER,
          user: null,
        });
        setDropdown(!showdropdown);
      })
      .catch((err) => {
        alert(err.message);
      });
  };

  useEffect(() => {
    if (search !== '' && s !== '' && search.length > 3) {
      if (s === 'akun') {
        fetch("https://mongo.instaforex.io/srca?a=" + search)
          .then(response => response.json())
          .then(r => {
            fsetakun(r);
            console.log(r);
          })
      }
      else if (s === 'nama') {
        fetch("https://mongo.instaforex.io/srctext?t=" + search)
          .then(response => response.json())
          .then(r => {
            fsetakun(r);
            console.log(r);
          })
      }
      else if (s === 'telp') {
        fetch("https://mongo.instaforex.io/srcp?p=" + search)
          .then(response => response.json())
          .then(r => {
            fsetakun(r);
            console.log(r);
          })
      }
    }
  }, [search, s]);

  return (
    <div className={hide ? "sidebar side__bar" : "sidebar"}>
      <div className="sidebar__header">
        <Avatar src={user?.photoURL} />
        <div className="sidebar__headerRight">
          <IconButton
            onClick={() =>
              alert(
                "Not added this functionality.\nClick on three dots to logout and add new room."
              )
            }
          >
            <DonutLargeIcon />
          </IconButton>
          <IconButton>
            <ChatIcon />
          </IconButton>
          <ClickAwayListener onClickAway={() => setDropdown(false)}>
            <div className="dropdown">
              <IconButton
                onClick={() => {
                  setDropdown(!showdropdown);
                }}
              >
                <MoreVertIcon />
              </IconButton>
              <div
                className={
                  showdropdown ? "dropdown__list" : "dropdown__list hide"
                }
              >
                <ul>
                  {/* <li onClick={createChat}>Add Room</li>*/}
                  <li onClick={signOut}>Log Out</li>
                  <li
                    onClick={() =>
                      alert(
                        "Not added this functionality.\nTry Logout and add Room options"
                      )
                    }
                  >
                    Help ?
                  </li>
                </ul>
              </div>
            </div>
          </ClickAwayListener>
        </div>
      </div>
      <div className="sidebar__search">
        <div className="sidebar__searchContainer">
          <SearchOutlined />
          <input
            placeholder="Pencarian"
            type="text"
            value={search}
            onClick={mainPage}
            onChange={(e) => {
              setSearch(e.target.value);
            }}
          />
        </div>
        <div>
          <Select
            labelId="demo-customized-select-label"
            id="demo-customized-select"
            value={s}
            onChange={handleChange}
          >
            <MenuItem value={'akun'}>akun</MenuItem>
            <MenuItem value={'nama'}>nama</MenuItem>
            <MenuItem value={'telp'}>telp</MenuItem>
          </Select>
        </div>
      </div>
      <div className="sidebar__chats">
        <SidebarChat addNewChat={true} phone={phone} />
        {rooms.map((room) => {
          if (
            room.data.name.toLowerCase().includes(search.toLowerCase()) ||
            search === ""
          ) {
            return (
              <SidebarChat key={room.id} id={room.id} name={room.data.name} />
            );
          }
          return <></>;
        })}
      </div>
    </div>
  );
}

export default Sidebar;
