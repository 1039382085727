import React, { useEffect, useState, useRef } from "react";
import { Avatar, IconButton } from "@material-ui/core";
import "./Chat.css";
import {
  AttachFile,
  InsertEmoticon,
  SearchOutlined,
  ArrowBack,
  Send,
  CloseOutlined
} from "@material-ui/icons";
import { Link, useParams } from "react-router-dom/cjs/react-router-dom.min";
import _ from 'lodash';
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';
import { formatDistance } from 'date-fns'
import { id } from 'date-fns/locale'
import Collapse from '@material-ui/core/Collapse';

function Chat({ hide, data }) {
  const messagesEndRef = useRef(null)
  const [input, setInput] = useState("");
  const { roomId } = useParams();
  const [open, setOpen] = useState(false);
  const [akun, setAkun] = useState([]);

  const scrollToBottom = () => {
    messagesEndRef.current.scrollIntoView({ behavior: "smooth" })
  }

  const filchat = _.reverse(_.filter(data, o => { return (o.chatId === roomId) }))
  const cd = _.find(data, o => o.from === roomId)

  console.log(filchat)

  useEffect(() => {
    setOpen(false);
    setTimeout(scrollToBottom(), 2000)
  }, [roomId]);

  const fp = () => {
    fetch("https://mongo.instaforex.io/srcp?p=" + roomId.split('@')[0])
      .then(response => response.json())
      .then(r => {
        setOpen(true);
        setAkun(r);
        setTimeout(scrollToBottom(), 3000)
      })
  }

  const sendMessage = () => {
    //e.preventDefault();
    if (input) {
      fetch('https://mongo.instaforex.io/sendwafromclone', {
        method: 'post',
        body: JSON.stringify({ from: roomId, mes: input }),
        headers: { 'Content-Type': 'application/json' }
      });
      setInput("");
    } else {
      alert("Type something first");
    }
  };
  const handleEnter = (e) => {
    if (e.key === 'Enter') {
      sendMessage()
    }
  }

  return (
    <div className={hide ? "chat Chat" : "chat"}>
      <div className="chat__header">
        <Link to="/">
          <IconButton>
            <ArrowBack />
          </IconButton>
        </Link>
        <Avatar src={cd?.sender.profilePicThumbObj?.imgFull ?? "https://pipsologi.instaforexindonesiafix.com/content/images/2022/04/instaforex-red-2.png"} />
        <div className="chat__headerInfo">
          <h4>{cd?.sender.pushname}</h4>
          <h6>{cd?.from.split('@')[0]}</h6>
        </div>
        <div className="chat__headerRight">
          {open ?
            <IconButton
              onClick={() => setOpen(false)}
            >
              <CloseOutlined />
            </IconButton>
            : <IconButton
              onClick={() => fp()}
            >
              <SearchOutlined />
            </IconButton>
          }

          {/*<IconButton>
            <AttachFile />s
          </IconButton>
          <ClickAwayListener onClickAway={() => setDropdown(false)}>
            <div className="dropdown">
              <IconButton
                onClick={() => {
                  setDropdown(!showdropdown);
                }}
              >
                <MoreVert />
              </IconButton>
              <div
                className={
                  showdropdown ? "dropdown__list" : "dropdown__list hide"
                }
              >
                <ul>
                  <Link to="/">
                    <li
                      onClick={() => {
                        removeRoom(roomId);
                      }}
                    >
                      Delete Room
                    </li>
                  </Link>
                </ul>
              </div>
            </div>
          </ClickAwayListener>
          */}
        </div>
      </div>
      <div className="chat__body">
        {filchat.map((mes) => (
          <div key={mes._id} id={mes._id}>
            <p
              className={`chat__message ${mes.from === '447480820464@c.us' && "chat__receiver"
                }`}
            >
              <span className="chat__name">{mes.notifyName}</span>
              <span className="pr">{
                mes.type === 'chat' && mes.body !== undefined
                  ? mes.body.replaceAll('`', '').replaceAll('_', '').replaceAll('*', '')
                  : mes.type === 'image'
                    ? <div><Zoom><img alt="" width="300px" src={`https://img.ditukar.in/${mes.id.replace('@', '').replace('.', '')}.jpg`}></img></Zoom>
                      <br />{mes.caption}</div>
                    : "*lampiran tidak dikenal"}</span>
              <span className="chat__timestamp">
                {formatDistance(new Date(mes.t * 1000), new Date(), { addSuffix: true, locale: id })}
              </span>
            </p>

          </div>
        ))}

        <Collapse in={open}>
          <div className="collapse">
            <table>
              <tr>
                <th>
                  n
                </th>
                <th>
                  tgl
                </th>
                <th>
                  akun
                </th>
                <th>
                  nama
                </th>
                <th>
                  alamat
                </th>
                <th>
                  kota
                </th>
              </tr>
              {akun.map((val, i) => (
                <tr>
                  <th>
                    {i}
                  </th>
                  <th>
                    {val.date.substring(0, 10)}
                  </th>
                  <th>
                    {val.account}
                  </th>
                  <th>
                    {val.name}
                  </th>
                  <th>
                    {val.adr}
                  </th>
                  <th>
                    {val.city}
                  </th>
                </tr>
              ))}
            </table>
          </div>
        </Collapse>
        <div ref={messagesEndRef} id='bottom'>..</div>
      </div>

      <div className="chat__footer">
        <IconButton
          onClick={() =>
            alert(
              "Not added this functionality.\nClick on three dots on top right to delete room."
            )
          }
        >
          <InsertEmoticon />
        </IconButton>
        <IconButton
          className="attach__file"
          onClick={() =>
            alert(
              "Not added this functionality.\nClick on three dots on top right to delete room."
            )
          }
        >
          <AttachFile />
        </IconButton>
        <form onSubmit={() => sendMessage()}>
          <input
            required={true}
            type="text"
            onChange={(e) => setInput(e.target.value)}
            value={input}
            placeholder="Type a message"
            onKeyPress={handleEnter}

          />
          <button type="submit">
            Send a message
          </button>
        </form>

        <IconButton
          onClick={() =>
            sendMessage()
          }
        >
          <Send />
        </IconButton>
        {/*
        <IconButton
          style={{ backgroundColor: "grey" }}
          onClick={() =>
            scrollToBottom()
          }
        >
          <ArrowDownward />
        </IconButton>
        */}
      </div>
    </div>
  );
}

export default Chat;
