import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDpoxIcn2tQ9lV9Kk01P2nV9VYL9HTCbdM",
  authDomain: "ditukarin-7d7bb.firebaseapp.com",
  databaseURL: "https://ditukarin-7d7bb.firebaseio.com",
  projectId: "ditukarin-7d7bb",
  storageBucket: "ditukarin-7d7bb.appspot.com",
  messagingSenderId: "501112638644",
  appId: "1:501112638644:web:8009d062abe9b8c66a1be1"
};

const firebaseApp = firebase.initializeApp(firebaseConfig);
const db = firebaseApp.firestore();
const auth = firebase.auth();

const provider = new firebase.auth.GoogleAuthProvider();


export { auth, provider };
export default db;
